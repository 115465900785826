import styles from './ItemDetails.module.scss';
// import WatchCondition1 from '../../../../assets/watchCondition1.jpg';
import { useCallback, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import { WatchInfoBuyDetails } from '../../../../models/WatchInfoBuyDetails';
// import {ReactComponent as GavelIcon} from '../../../../assets/gavel.svg';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BidHistoryTree from './BidHistoryTree';
// import {ReactComponent as SellerDetailsGavelIcon} from '../../../../assets/sellerDetailsGavel.svg';
// import {ReactComponent as CartIcon} from '../../../../assets/shoppingCart.svg';
import { useMediaQuery } from "react-responsive";
import { MobileScreenWidth } from '../../../../../constants';
import ConditionTooltip from '../../../conditionTooltip/ConditionTooltip';
import Parser from 'html-react-parser'

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
  
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        sx: { minHeight: '36px', height: '36px' }
    };
}

export interface ItemDetailsProps {
    watchDetails: WatchInfoBuyDetails;    
}

const ItemDetails = ({watchDetails}: ItemDetailsProps) => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });

    const [selectedTab, setSelectedTab] = useState(0);
    const [showBidHistory, setShowBidHistory] = useState(false);
    const [showCondition, setShowCondition] = useState(false);

    const onTabChanged = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    }, []);

    return (
        <div className={styles.root}>
            {/* {!isDesktop &&
                <div className={styles.highestBidderContainerMobile}>
                    <div className={styles.highestBidderContainer}>
                        <Typography className={styles.highestBidderLabel}>YOU'RE THE HIGHEST BIDDER</Typography>
                    </div>
                </div>
            } */}

            {isDesktop ?
                <div className={styles.mainInfoContainer}>
                    <div className={styles.nameContainer}>
                        <Typography className={styles.brandLabel}>{watchDetails.brand}</Typography>
                        <Typography className={styles.modelLabel}>{watchDetails.model}</Typography>
                        <Typography className={styles.yearLabel}>{watchDetails.year}</Typography>
                    </div>
                    
                    {/* <div className={styles.bidDetailsContainer}>
                        <div className={styles.highestBidderContainer}>
                            <Typography className={styles.highestBidderLabel}>YOU'RE THE HIGHEST BIDDER</Typography>
                        </div>

                        <div className={styles.bidDetailsLowerContainer}>
                            <div className={styles.currentBidContainer}>
                                <Typography className={watchDetails.currentBid ? styles.currentBidLabel : styles.currentBidLabelNoValue}>CURRENT BID</Typography>
                                <Typography className={styles.currentBidValue}>{watchDetails.currentBid ? `£${watchDetails.currentBid.toLocaleString()}` : ''}</Typography>
                            </div>
                            
                            <div className={styles.spacerContainer}>
                                <div className={styles.spacer} />
                            </div>

                            <div className={styles.favouritesContainer}>
                                <Typography className={styles.bidCountValue}>{watchDetails.bids}</Typography>
                                <GavelIcon className={styles.gavelIcon}/>
                                <Typography className={styles.gavelBase}>_</Typography>
                                <Typography className={styles.favouritesCountValue}>{watchDetails.savedAsFavouriteCount}</Typography>
                                <FavoriteIcon className={styles.favouriteIcon}/>
                            </div>
                        </div>
                    </div> */}
                </div> 
                :
                <div className={styles.mainInfoContainer}>
                    <div className={styles.nameContainer}>
                        <Typography className={styles.brandLabelMobile}>{watchDetails.brand}</Typography>
                        <Typography className={styles.modelLabelMobile}>{watchDetails.model}</Typography>
                        <Typography className={styles.yearLabelMobile}>{watchDetails.year}</Typography>
                    </div>
                    
                    {/* <div className={styles.bidDetailsContainer}>
                        <div className={styles.bidDetailsLowerContainerMobile}>
                            <div className={styles.currentBidContainerMobile}>
                                <Typography className={watchDetails.currentBid ? styles.currentBidLabel : styles.currentBidLabelNoValue}>CURRENT BID</Typography>
                                <Typography className={styles.currentBidValue}>{watchDetails.currentBid ? `£${watchDetails.currentBid.toLocaleString()}` : ''}</Typography>
                            </div>
                            
                            <div className={styles.spacerContainerMobile}>
                                <div className={styles.spacer} />
                            </div>

                            <div className={styles.favouritesContainerMobile}>
                                <Typography className={styles.bidCountValue}>{watchDetails.bids}</Typography>
                                <GavelIcon className={styles.gavelIcon}/>
                                <Typography className={styles.gavelBase}>_</Typography>
                                <Typography className={styles.favouritesCountValue}>{watchDetails.savedAsFavouriteCount}</Typography>
                                <FavoriteIcon className={styles.favouriteIcon}/>
                            </div>
                        </div>
                    </div> */}
                </div>
            }

            <Box className={styles.tabsContainer}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTab} onChange={onTabChanged} className={styles.tabControl} sx={{ minHeight: '36px', height: '36px' }}>
                        <Tab label="Summary" {...a11yProps(0)} disableRipple />
                        <Tab label="Specification" {...a11yProps(1)} disableRipple />
                        <Tab label="Seller Details" {...a11yProps(2)} disableRipple />
                    </Tabs>
                </Box>
                <div className={styles.tabContentContainer}>
                    <TabPanel value={selectedTab} index={0}>
                        {watchDetails.summary.split('\n').map((line, index) => line === '||' ? (
                            <div key={index} className={styles.summarySpacer} />
                        ) : (
                            <Typography key={index} className={styles.summaryText}>
                                {Parser(line)}
                            </Typography>
                        ))}
                        <div className={styles.summaryLineContainer}>
                            <div className={styles.summaryLine} />
                        </div>
                        <Typography className={styles.suffixText}>
                            All watches listed for auction are cleared against The Watch Register database before being accepted for consignment.  A certificate to reflect this is available to the winning bidder on request.
                        </Typography>
                        <Typography className={styles.suffixText}>
                            Please be aware that all watches should be assumed to require a service to bring them back within manufacturer’s specifications. Some watches will remain within the manufacturer’s warranty period at the point of being auctioned and that warranty may be transferrable to the winning bidder. Please check before bidding.
                        </Typography>
                        <Typography className={styles.suffixText}>
                            All activity on this site is subject to the Terms and Conditions which can be found here.
                        </Typography>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <div className={styles.specificationContainer}>
                            <Stack direction={'column'}>
                                <Stack direction={'row'} className={styles.specificationValueContainer}>
                                    <Typography className={styles.specificationLabel}>Model Number:</Typography>
                                    <Typography className={styles.specificationValue}>{watchDetails.specification.modelNumber}</Typography>
                                </Stack>
                                <Stack direction={'row'} className={styles.specificationValueContainer}>
                                    <Typography className={styles.specificationLabel}>Condition:</Typography>
                                    <Typography className={styles.specificationValue}>{watchDetails.specification.condition}</Typography>
                                    <ConditionTooltip isLarge={true} />
                                </Stack>
                                <Stack direction={'row'} className={styles.specificationValueContainer}>
                                    <Typography className={styles.specificationLabel}>Material:</Typography>
                                    <Typography className={styles.specificationValue}>{watchDetails.specification.material}</Typography>
                                </Stack>
                                <Stack direction={'row'} className={styles.specificationValueContainer}>
                                    <Typography className={styles.specificationLabel}>Movement:</Typography>
                                    <Typography className={styles.specificationValue}>{watchDetails.specification.movement}</Typography>
                                </Stack>
                                <Stack direction={'row'} className={styles.specificationValueContainer}>
                                    <Typography className={styles.specificationLabel}>Lot:</Typography>
                                    <Typography className={styles.specificationValue}>{watchDetails.specification.lot}</Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={'column'}>
                                <Stack direction={'row'} className={styles.specificationValueContainer}>
                                    <Typography className={styles.specificationLabel}>Case:</Typography>
                                    <Typography className={styles.specificationValue}>{watchDetails.specification.case}</Typography>
                                </Stack>
                                <Stack direction={'row'} className={styles.specificationValueContainer}>
                                    <Typography className={styles.specificationLabel}>Box:</Typography>
                                    <Typography className={styles.specificationValue}>{watchDetails.specification.box}</Typography>
                                </Stack>
                                <Stack direction={'row'} className={styles.specificationValueContainer}>
                                    <Typography className={styles.specificationLabel}>Papers:</Typography>
                                    <Typography className={styles.specificationValue}>{watchDetails.specification.papers}</Typography>
                                </Stack>
                                <Stack direction={'row'} className={styles.specificationValueContainer}>
                                    <Typography className={styles.specificationLabel}>Waterproof:</Typography>
                                    <Typography className={styles.specificationValue}>{watchDetails.specification.waterproof}</Typography>
                                </Stack>
                            </Stack>                                    
                        </div>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                    <div className={styles.sellerDetailsContainer}>
                            <Stack direction={'column'}>
                                <Stack direction={'row'} className={styles.sellerDetailsValueContainer}>
                                    <Typography className={styles.sellerDetailsLabel}>Seller:</Typography>
                                    <Typography className={styles.sellerDetailsValue}>{watchDetails.sellerInfo.user}</Typography>
                                </Stack>
                                <Stack direction={'row'} className={styles.sellerDetailsValueContainer}>
                                    <Typography className={styles.sellerDetailsLabel}>Seller Type:</Typography>
                                    {watchDetails.sellerInfo.sellerType && 
                                    <>
                                        <Typography className={styles.sellerDetailsValue}>{watchDetails.sellerInfo.sellerType}</Typography>
                                        {/* <SellerDetailsGavelIcon className={styles.gavelIcon} />
                                        <CartIcon className={styles.cartIcon} /> */}
                                    </>}
                                </Stack>
                            </Stack>
                            <Stack direction={'column'}>
                                <Stack direction={'row'} className={styles.sellerDetailsValueContainer}>
                                    <Typography className={styles.sellerDetailsLabel}>Location:</Typography>
                                    <Typography className={styles.sellerDetailsValue}>{watchDetails.sellerInfo.location}</Typography>
                                </Stack>
                            </Stack>                                    
                        </div>
                    </TabPanel>
                </div>
            </Box>

            <div className={styles.emailContainer}>
                <Typography className={styles.emailText}>We are opening the Watch Shuffle showroom for browsing in July and your opportunity to bid on watches will follow in Q4 2023.</Typography>
                <Typography className={styles.emailText}>Our launch will be supported with advertising across TV, Outdoor media and competitions!</Typography>
                <Typography className={styles.emailText}>Register below to be kept updated!</Typography>
                <div className={styles.emailFormContainer}>
                    <iframe className={styles.emailForm} title="SendGridSignUpForm" src="https://cdn.forms-content.sg-form.com/a22d90cc-b81c-11ed-a8e7-f211d972171f"/>
                </div>
            </div>

            {/* <div className={styles.createBidContainer}>
                <div className={styles.minBidContainer}>
                    <div className={styles.bidEntryContainer}>
                        <Typography className={styles.currencyLabel}>GBP (£)</Typography>
                        <div className={styles.inputContainer}>
                            <Typography className={styles.minBidLabel}>Current Minimum Bid</Typography>
                            <TextField
                                className={styles.bidInput}
                                fullWidth
                                id="bidInput"
                                InputLabelProps={{
                                    style: {
                                        fontSize: '11px',
                                        fontWeight: '400'
                                    }
                                }}
                                variant="standard"
                            />
                        </div>
                    </div>

                    <div className={styles.bidButtonsContainer}>
                        <Button className={styles.addFavouritesButton} variant="contained" disableElevation>
                            <Typography className={styles.addFavouritesText}>Add to Favourites</Typography>
                        </Button>
                        <Button className={styles.placeBidButton} variant="contained" disableElevation>
                            <Typography className={styles.placeBidText}>Place a Bid</Typography>
                        </Button>
                    </div>
                </div>

                {isDesktop &&
                    <Stack direction={'column'} className={styles.auctionEndsContainer}>
                        <Typography className={styles.auctionEndsLabel}>Auction Ends</Typography>
                        <div className={styles.timeRemainingContainer}>
                            <TimerOutlinedIcon className={styles.timerIcon}/>
                            <Typography className={styles.timeRemainingText}>08:22:18</Typography>
                        </div>
                    </Stack>
                }
            </div> */}

            {/* {!isDesktop &&
                <Stack direction={'row'} className={styles.auctionEndsContainerMobile}>
                    <Stack direction={'column'} className={styles.contentsContainer}>
                        <Typography className={styles.auctionEndsLabel}>Auction Ends</Typography>
                        <div className={styles.timeRemainingContainer}>
                            <TimerOutlinedIcon className={styles.timerIcon}/>
                            <Typography className={styles.timeRemainingText}>08:22:18</Typography>
                        </div>
                    </Stack>
                </Stack>
            } */}

            <Stack direction={'row'} className={styles.auctionStartsContainerMobile}>
                    <Stack direction={'column'} className={styles.contentsContainer}>
                        <Typography className={styles.auctionStartsLabel}>Auction Ends</Typography>
                        <Typography className={styles.auctionStartsValue}>Q4 2023</Typography>
                    </Stack>
                </Stack>

            <div className={styles.bidHistoryContainer}>
                <Accordion elevation={0} expanded={showBidHistory} onChange={() => setShowBidHistory(prev => !prev)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={styles.titleLabel}>Bid History</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {watchDetails.bidHistory.length ? (
                            <BidHistoryTree bidHistory={watchDetails.bidHistory} />
                        ) : (
                            <div className={styles.bidHistoryNoItemsContainer}>
                                <Typography className={styles.bidHistoryNoItems}>Currently no history</Typography>
                            </div>
                        )}

                    </AccordionDetails>
                </Accordion>                        
            </div>

            <div className={styles.conditionContainer}>
                <Accordion elevation={0} expanded={showCondition} onChange={() => setShowCondition(prev => !prev)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={styles.titleLabel}>Condition</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={styles.conditionContentContainer}>
                            {/* <div className={styles.conditionInfoContainer}>
                                <div className={styles.imagesContainer}>
                                    <img className={styles.watchConditionImage} src={WatchCondition1} alt="Condition" />
                                </div>
                                <Typography className={styles.conditionText}>{watchDetails.conditionDetails.description}</Typography>
                            </div>

                            <Typography className={styles.warningText}>Please note: all condition overviews are provided by the seller</Typography> */}

                            <div className={styles.conditionNoInfoContainer}>
                                <Typography className={styles.conditionNoInfo}>No additional condition information</Typography>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

            {/* <div className={styles.commentContainer}>
                <div className={styles.commentInputContainer}>
                    <TextField 
                        className={styles.commentInput} 
                        multiline={true}
                        rows={5}
                        variant='filled' 
                        placeholder='Leave a comment here' />
                </div>
                <div className={styles.postCommentContainer}>
                    <Button className={styles.postCommentButton} variant="contained" disableElevation>
                        <Typography className={styles.postCommentText}>Post Comment</Typography>
                    </Button>
                </div>
            </div> */}
        </div>
    );
}

export default ItemDetails;