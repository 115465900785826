import { Typography } from "@mui/material";
import styles from './BidHistoryTree.module.scss';
import { BidHistoryItem } from "../../../../models/WatchInfoBuyDetails";
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import {ReactComponent as GavelIcon} from '../../../../assets/gavel.svg';
import {ReactComponent as CartIcon} from '../../../../assets/cart.svg';

export interface BidHistoryTreeProps {
    bidHistory: BidHistoryItem[];    
}

const formatBid = new Intl.NumberFormat('en-GB', {
    maximumFractionDigits: 0
});

const BidHistoryTree = ({bidHistory}: BidHistoryTreeProps) => {
    return (
        <div className={styles.root}>
            {bidHistory.map((item, index) => (
                <>
                    {index !== 0 ? (
                        <div style={{gridColumn: 2}} className={styles.itemSeparator}>
                            <div className={styles.itemSpacer}/>
                        </div>
                    ) : null}

                    <div style={{gridColumn: 1}} className={styles.detailsLeftContainer}>
                        <Typography className={styles.dateText}>{item.bidDate}</Typography>
                        <Typography className={styles.dateDescriptionText}>{item.bidDateDescription}</Typography>
                    </div>

                    <div style={{gridColumn: 2}} className={styles.detailsSeparator}>
                        {index === 0 ? <CircleIcon  className={styles.separatorHighestIcon}/> : <CircleOutlinedIcon  className={styles.separatorIcon}/>}
                    </div>

                    <div style={{gridColumn: 3}} className={styles.detailsRightContainer}>
                        <Typography className={styles.bidText}>£{formatBid.format(item.bidAmount)}</Typography>
                        <div className={styles.userContainer}>
                            <Typography className={styles.userText}>{item.user}</Typography>

                            {index !== bidHistory.length - 1 ? (
                                <>
                                    <GavelIcon className={styles.gavelIcon}/>
                                    <Typography className={styles.gavelBase}>_</Typography>
                                </>
                            ) : null}
                            
                            {index !== 0 ? (
                                <CartIcon className={styles.gavelIcon}/>
                            ) : null}
                        </div>
                    </div>
                </>
            ))}
        </div>
    );
}

export default BidHistoryTree;