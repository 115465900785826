import styles from './CopyrightFooter.module.scss';
import { Typography } from "@mui/material";

const CopyrightFooter = () => {
    return (
        <div className={styles.copyrightTextContainer}>
            <Typography className={styles.copyrightText}>
                Copyright Watch Shuffle. All rights reserved.
            </Typography>
        </div>
    );
}

export default CopyrightFooter;