import styles from './AuctionBuyDetails.module.scss';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Footer from '../../../footer/Footer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { WatchInfoBuyDetails } from '../../../../models/WatchInfoBuyDetails';
import { getWatchBuyDetails } from '../../../../services/auction-service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Scrollbar, Mousewheel } from "swiper";
import ItemDetails from './ItemDetails';
import { useMediaQuery } from "react-responsive";
import LightGallery from 'lightgallery/react';
import { LightGallery as LightGalleryPlugin } from "lightgallery/lightgallery";
import { InitDetail } from "lightgallery/lg-events";
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { LightGalleryLicense, MobileScreenWidth } from '../../../../../constants';
import { MobileSettings } from 'lightgallery/lg-settings';
import Slider from "react-slick";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';


const AuctionBuyDetails = () => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });

    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const [watchDetails, setWatchDetails] = useState<WatchInfoBuyDetails|undefined>();
    const [watchImages, setWatchImages] = useState<any[]>([]);
    const [selectedSlide, setSelectedSlide] = useState<number>(0);
    const lightGalleryRef = useRef<LightGalleryPlugin>();
    const navigate = useNavigate();

    const onInit = (detail: InitDetail) => {
        lightGalleryRef.current = detail.instance;
    };

    const handleGalleryShow = (e: React.MouseEvent<HTMLAnchorElement>, index: number) => {
        e.preventDefault();

        if (lightGalleryRef.current) {
            lightGalleryRef.current.openGallery(index);
        }
    }

    const mobileSettings: Partial<MobileSettings> = {
        showCloseIcon: true
    };

    const sliderSettings = {
        arrows: false,
        dots: false,
        beforeChange: (_: number, nextSlide: number) => setSelectedSlide(nextSlide),
    };

    const handleBackClick = useCallback(() => {
        navigate("/buy");
    }, [navigate]);      

    useEffect(() => {
        const fetchWatchDetails = async (watchId: string) => {
            const details = await getWatchBuyDetails(watchId);
            setWatchDetails(details);
        }

        if (id) {
            fetchWatchDetails(id);
        }
    }, [id]);

    useEffect(() => {
        const importAllImages = async (images: string[]) => {
            const importedImages = [];

            for(let i=0; i<images.length; i++) {
                const importedImage = await import(`../../../../assets/${images[i]}`);
                importedImages.push(importedImage.default);
            }

            setWatchImages(importedImages);
        }
        
        if (watchDetails?.images.length) {
            importAllImages(watchDetails.images);
        } else {
            setWatchImages([]);
        }
    }, [watchDetails?.images]);

    if (!watchDetails) {
        return (
            <div className={styles.root}>
                <div className={styles.backButtonContainer}>
                    <Button className={styles.backButton} variant="contained" disableElevation onClick={handleBackClick}>
                        <KeyboardArrowLeftIcon className={styles.backIcon} />
                        <Typography className={styles.backLabel}>Back to Auctions</Typography>
                    </Button>
                </div>
                <div className={styles.noInfoContainer}>
                    <Typography className={styles.noInfoLabel}>No watch details found</Typography>
                </div>
            </div>
        );
    }

    if (isDesktop) {
        return (
            <div className={styles.root}>
                <div className={styles.backButtonContainer}>
                    <Button className={styles.backButton} variant="contained" disableElevation onClick={handleBackClick}>
                        <KeyboardArrowLeftIcon className={styles.backIcon} />
                        <Typography className={styles.backLabel}>Back to Auctions</Typography>
                    </Button>
                </div>
                <div className={styles.contentContainer}>
                    <div style={{gridColumn: 1}} className={styles.leftContentContainer}>
                        <Swiper
                            direction={"vertical"}
                            slidesPerView={"auto"}
                            freeMode={true}
                            scrollbar={true}
                            mousewheel={true}
                            modules={[FreeMode, Scrollbar, Mousewheel]}
                            className={styles.imagesContainer}
                        >
                            <SwiperSlide>
                                <LightGallery
                                    licenseKey={LightGalleryLicense}
                                    speed={500}
                                    plugins={[lgThumbnail, lgZoom]}
                                    getCaptionFromTitleOrAlt={false}
                                    download={false}
                                    showCloseIcon={true}
                                >
                                    {watchImages.map(watchImage => 
                                        <a key={watchImage} className={styles.imageOverlayLink} href={watchImage}><img className={styles.watchImage} src={watchImage} alt="Watch" /><ZoomOutMapIcon className={styles.overlayIcon} /></a>
                                    )}
                                </LightGallery>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div style={{gridColumn: 2}} className={styles.rightContentContainer}>
                        <ItemDetails watchDetails={watchDetails} />
                    </div>
                </div>
                
                <Footer hideInfoLinks={true} />
            </div>
        );
    } else {
        return (
            <div className={styles.root}>
                <div className={styles.lightGalleryContainer}>
                    <LightGallery
                        onInit={onInit}
                        licenseKey={LightGalleryLicense}
                        speed={500}
                        plugins={[lgThumbnail, lgZoom]}
                        getCaptionFromTitleOrAlt={false}
                        download={false}
                        showCloseIcon={true}
                        mobileSettings={mobileSettings}
                    >
                        {watchImages.map(watchImage =>
                            <a key={watchImage} href={watchImage}><img alt="Watch" src={watchImage} /></a>
                        )}
                    </LightGallery>
                </div>
                <div className={styles.mobileImagesContainer}>
                    <Slider {...sliderSettings}>
                        {watchImages.map((watchImage, index) =>
                            <div key={watchImage} className={styles.imageContainer}>
                                <a className={styles.imageOverlayLink} href='/' onClick={e => handleGalleryShow(e, index)}>
                                    <img className={styles.image} src={watchImage} alt="Watch" />
                                    <ZoomOutMapIcon className={styles.overlayIcon} />
                                </a>
                            </div>
                        )}
                    </Slider>
                </div>
                {watchImages.length &&
                    <div className={styles.mobileSliderDots}>
                        {watchImages.map((_, index) =>
                            index === selectedSlide ? 
                                <CircleIcon key={index} className={styles.sliderDot} /> :
                                <CircleOutlinedIcon key={index} className={styles.sliderDot} />
                        )}
                    </div>
                }

                <div className={styles.mobileItemDetailsContainer}>
                    <ItemDetails watchDetails={watchDetails} />
                </div>

                <Footer hideInfoLinks={true} />
            </div>
        );
    }
}

export default AuctionBuyDetails;