import { Button, IconButton, Stack, Typography } from "@mui/material";
import { WatchInfoBuy } from "../../../../models/WatchInfoBuy";
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import styles from './AuctionItem.module.scss';
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConditionTooltip from "../../../conditionTooltip/ConditionTooltip";

export interface AuctionItemProps {
    info: WatchInfoBuy    
    onViewDetails?: (info: WatchInfoBuy) => void;
    onFavouritesToggle?: (info: WatchInfoBuy) => void;
}

const AuctionItem = ({info, onViewDetails, onFavouritesToggle}: AuctionItemProps) => {
    const [watchImage, setWatchImage] = useState<any>();
    const navigate = useNavigate();

    useEffect(() => {
        import(`../../../../assets/${info.showroomImage}`).then(image => {
            setWatchImage(image.default);
        });
    }, [info.showroomImage]);

    // const handleFavouriteToggle = useCallback(() => {
    //     // Nothing for now
    // }, []);

    const handleViewDetailsClick = useCallback(() => {
        navigate(`/details?id=${info.id}`);
    }, [info.id, navigate]);    

    return (
        <IconButton className={styles.root} onClick={handleViewDetailsClick} disableFocusRipple disableRipple>
            <div className={styles.contentContainer}>
                <div className={styles.brandContainer}>
                    <Typography className={styles.brandTitle}>{info.brand}</Typography>
                    {/* <div className={styles.favouriteContainer}>
                        <IconButton aria-label={info.isFavourite ? 'Remove from favourites' : 'Add to favourites'} onClick={handleFavouriteToggle}>
                            {info.isFavourite ? <FavoriteIcon className={styles.favouriteIcon} /> : <FavoriteBorderIcon className={styles.favouriteIcon} />}
                        </IconButton>                        
                    </div> */}
                </div>

                <div className={styles.modelContainer}>
                    <Typography className={styles.modelTitle} noWrap>{info.model}</Typography>
                </div>

                <div className={styles.yearContainer}>
                    <Typography className={styles.yearTitle}>{info.year}</Typography>
                </div>

                <div className={styles.watchImageContainer}>
                    {watchImage && <img className={styles.watchImage} src={watchImage} alt="" />}
                </div>

                <div className={styles.infoContainerComingSoon}>
                    <div className={styles.conditionContainer}>
                        <Typography className={styles.conditionTitle}>Condition</Typography>
                        <Stack direction={'row'} className={styles.conditionValueContainer}>
                            <Typography className={styles.conditionValue}>{info.condition}</Typography>
                            <ConditionTooltip isLarge={false}/>
                        </Stack>
                    </div>

                    <div className={styles.auctionStartsContainer}>
                        <Typography className={styles.auctionStartsTitle}>Auction Ends</Typography>
                        <Typography className={styles.auctionStartsValue}>Q4 2023</Typography>                   
                    </div>
                </div>

                <div className={styles.detailsContainerComingSoon}>
                    <div className={styles.detailsButtonContainer}>
                        <Button className={styles.detailsButton} variant="contained" disableElevation onClick={handleViewDetailsClick}>
                            <Typography className={styles.details}>View Details</Typography>
                        </Button>
                    </div>
                </div>

                {/* <div className={styles.infoContainer}>
                    <div className={styles.conditionContainer}>
                        <Typography className={styles.conditionTitle}>CONDITION</Typography>
                        <Typography className={styles.conditionValue}>{info.condition}</Typography>
                    </div>

                    <div className={styles.bidsContainer}>
                        <Typography className={styles.bidsTitle}>BIDS</Typography>
                        <Typography className={styles.bidsValue}>{info.bids}</Typography>
                    </div>

                    <div className={styles.auctionEndContainer}>
                        <Typography className={styles.auctionEndTitle}>AUCTION ENDS</Typography>
                        <div className={styles.endTimeContainer}>
                            <TimerOutlinedIcon className={styles.timerIcon} />
                            <Typography className={styles.auctionEndValue}>01:00:32</Typography>
                        </div>                    
                    </div>
                </div>

                <div className={styles.detailsContainer}>
                    <div className={styles.currentBidContainer}>
                        <Typography className={styles.currentBidTitle}>CURRENT BID</Typography>
                        <Typography className={styles.currentBidValue}>{info.currentBid ? `£${info.currentBid.toLocaleString()}` : ''}</Typography>
                    </div>

                    <div className={styles.spacer} />

                    <div className={styles.detailsButtonContainer}>
                        <Button className={styles.detailsButton} variant="contained" disableElevation onClick={handleViewDetailsClick}>
                            <Typography className={styles.details}>View Details</Typography>
                        </Button>
                    </div>
                </div> */}
            </div>
        </IconButton>
    );
}

export default AuctionItem;