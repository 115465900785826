import styles from './AuctionBuy.module.scss';
import AuctionBuyImage1 from '../../../../assets/auctionBuyImage1.png';
import AuctionBuyImage1Mobile from '../../../../assets/auctionBuyImage1Mobile.png';
import Footer from '../../../footer/Footer';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { StatusFilter } from './StatusFilter';
import { InputAdornment, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { WatchInfoBuy } from '../../../../models/WatchInfoBuy';
import { getWatchesByStatus } from '../../../../services/auction-service';
import AuctionItem from './AuctionItem';
import SearchIcon from '@mui/icons-material/Search';
// import { PageSelector } from './PageSelector';
import { useMediaQuery } from "react-responsive";
import { MobileScreenWidth } from '../../../../../constants';
import AuctionItemMobile from './AuctionItemMobile';

const initialStatusToCount = {
    comingsoon: 6,
    live: 0,
    sold: 0
}

const AuctionBuy = () => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });

    const [selectedStatus, setSelectedStatus] = useState<string>('comingsoon');
    const [statusToCount] = useState<Record<string, number>>(initialStatusToCount);
    const [sellerType, setSellerType] = useState<string>('private');
    const [sortBy, setSortBy] = useState<string>('endingSoon');
    const [searchText, setSearchText] = useState<string>('');
    const [auctionItems, setAuctionItems] = useState<WatchInfoBuy[]>([]);
    const [displayedAuctionItems, setDisplayedAuctionItems] = useState<WatchInfoBuy[]>([]);
    // const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        (async () => {
            const items = await getWatchesByStatus(selectedStatus);
            setAuctionItems(items);
        })();
    }, [selectedStatus]);

    useEffect(() => {
        const searchTextLowerCase = searchText.toLowerCase();
        const display = [...auctionItems.filter(item => (!sellerType || item.sellerType === sellerType) && (!searchTextLowerCase || item.brand.toLowerCase().includes(searchTextLowerCase) || item.model.toLowerCase().includes(searchTextLowerCase) || item.year.toLowerCase().includes(searchTextLowerCase)))];

        switch (sortBy) {
            case 'brand': display.sort((a,b) => a.brand.localeCompare(b.brand)); break;
            case 'model': display.sort((a,b) => a.model.replace('"', '').localeCompare(b.model.replace('"', ''))); break;
            case 'endingSoon': display.sort((a,b) => {
                const value1 = a.auctionEnd?.getTime() ?? 0;
                const value2 = b.auctionEnd?.getTime() ?? 0;
                return value1 === value2 ? 0 : value1 < value2 ? -1 : 1;
            });
            break;
            case 'currentBid': display.sort((a,b) => a.currentBid === b.currentBid ? 0 : a.currentBid < b.currentBid ? -1 : 1); break;
        }

        setDisplayedAuctionItems(display);
    }, [auctionItems, searchText, sellerType, sortBy]);

    const onStatusFilterChanged = useCallback((value: string) => {
        setSelectedStatus(value);
    }, []);

    const onSellerTypeChange = useCallback((event: SelectChangeEvent) => {
        setSellerType(event.target.value);
    }, []);

    const onSortByChange = useCallback((event: SelectChangeEvent) => {
        setSortBy(event.target.value);
    }, []);

    // const onPageChanged = useCallback((value: number) => {
    //     setCurrentPage(value);
    // }, []);

    const statusClasses = {
        standard: styles.statusDropdown,
        select: styles.statusDropdownSelected
    };

    const onStatusChange = useCallback((event: SelectChangeEvent) => {
        setSelectedStatus(event.target.value);
    }, []);

    const sellerTypeClasses = {
        standard: styles.sellerTypeDropdown,
        select: styles.sellerTypeDropdownSelected
    };

    const sortByClasses = {
        standard: styles.sortByDropdown,
        select: styles.sortByDropdownSelected
    };

    const onSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }, []);

    return (
        <div className={styles.root}>
            <div className={styles.contentContainer}>
                <div className={styles.imageContainer1}>
                    <img className={styles.auctionBuyImage} src={isDesktop ? AuctionBuyImage1 : AuctionBuyImage1Mobile} alt="Auction Buy" />
                </div>
                
                {isDesktop ?
                    <div className={styles.filterPanel}>
                        <div className={styles.statusFilterContainer}>
                            <StatusFilter selected={selectedStatus} onChange={onStatusFilterChanged} statusToCount={statusToCount} />
                        </div>

                        <div className={styles.sellerTypeContainer}>
                            <Typography className={styles.sellerTypeLabel}>Seller Type:</Typography>
                            <div id='sellerTypeSelect' className={styles.sellerTypeDropdownContainer}>
                                <Select classes={sellerTypeClasses} variant="standard" value={sellerType} onChange={onSellerTypeChange}>
                                    <MenuItem value={'private'}>Private</MenuItem>
                                    <MenuItem value={'public'}>Public</MenuItem>
                                </Select>
                            </div>
                        </div>

                        <div className={styles.sortByContainer}>
                            <Typography className={styles.sortByLabel}>Sort By:</Typography>
                            <div className={styles.sortByDropdownContainer}>
                                <Select id='sortBySelect' classes={sortByClasses} variant="standard" value={sortBy} onChange={onSortByChange}>
                                    <MenuItem value={'brand'}>Brand</MenuItem>
                                    <MenuItem value={'model'}>Model</MenuItem>
                                    <MenuItem value={'endingSoon'}>Ending Soon</MenuItem>
                                    <MenuItem value={'currentBid'}>Current Bid</MenuItem>
                                </Select>
                            </div>
                        </div>

                        <div className={styles.spacer} />

                        <div className={styles.searchContainer}>
                            <TextField
                                id="searchField"
                                placeholder="Search"
                                type="search"
                                variant="filled"
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start" className={styles.searchIconContainer}>
                                        <SearchIcon className={styles.searchIcon} />
                                    </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    input: {
                                    "&::placeholder": {
                                        opacity: 1,
                                    }
                                    }
                                }}
                                value={searchText}
                                onChange={onSearchChange}
                            />
                        </div>                        
                    </div> :
                    <div className={styles.filterPanelMobile}>
                        <div className={styles.statusFilterContainerMobile}>
                            <Select classes={statusClasses} variant="standard" value={selectedStatus} onChange={onStatusChange}>
                                <MenuItem value={'comingsoon'}>Coming Soon ({statusToCount.comingsoon})</MenuItem>
                                <MenuItem value={'live'}>Live ({statusToCount.live})</MenuItem>
                                <MenuItem value={'sold'}>Sold ({statusToCount.sold})</MenuItem>
                            </Select>   
                        </div>
                        
                        <div className={styles.sellerTypeContainerMobile}>
                            <div id='sellerTypeSelect' className={styles.sellerTypeDropdownContainer}>
                                <Select classes={sellerTypeClasses} variant="standard" value={sellerType} onChange={onSellerTypeChange} placeholder='Filters'>
                                    <MenuItem value={'private'}>Private</MenuItem>
                                    <MenuItem value={'public'}>Public</MenuItem>
                                </Select>
                            </div>
                        </div>

                        <div className={styles.searchContainerMobile}>
                            <TextField
                                id="searchField"
                                placeholder="Search"
                                type="search"
                                variant="filled"
                                sx={{
                                    input: {
                                    "&::placeholder": {
                                        opacity: 1,
                                    }
                                    }
                                }}
                                value={searchText}
                                onChange={onSearchChange}
                            />
                        </div>
                    </div>
                }

                {!displayedAuctionItems.length ? (
                    <div className={isDesktop ? styles.noItemsContainer : styles.noItemsContainerMobile}>
                        <Typography className={styles.noItemsText}>No watches found for the selected criteria.</Typography>
                    </div>
                ) : (isDesktop ? (
                    <div className={styles.itemsContainer}>
                        {displayedAuctionItems.map(x => <AuctionItem key={x.id} info={x} />)}
                    </div>
                ) : (
                    <div className={styles.itemsContainerMobile}>
                        <div className={styles.itemsContainerInner}>
                            {displayedAuctionItems.map(x => <AuctionItemMobile key={x.id} info={x} />)}
                        </div>
                    </div>
                ))}

                {/* <div className={styles.pagesContainer}>
                    <PageSelector maxPages={15} pageSelected={currentPage} numPagesToDisplay={6} onPageChanged={onPageChanged} />
                </div> */}
            </div>
            
            <Footer hideInfoLinks={true} />
        </div>
    );
}

export default AuctionBuy;