import styles from './AuctionSellComingSoon.module.scss';
import AuctionSellImage1 from '../../../../assets/auctionSellImage1.jpg';
import AuctionSellImage1Mobile from '../../../../assets/auctionSellImage1Mobile.jpg';
import Footer from '../../../footer/Footer';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from "react-responsive";
import { MobileScreenWidth } from '../../../../../constants';

const AuctionSellComingSoon = () => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });

    return (
        <div className={styles.root}>
            <div className={styles.contentContainer}>
                <div className={styles.imageContainer1}>
                    <img className={styles.auctionSellImage} src={isDesktop ? AuctionSellImage1 : AuctionSellImage1Mobile} alt="Auction Sell" />
                </div>

                <div className={isDesktop ? styles.titleContainer : styles.titleContainerMobile}>
                    <Typography className={styles.titleText}>Coming Soon in Q4 2023</Typography>
                </div>
            </div>
            
            <Footer hideInfoLinks={true} />
        </div>
    );
}

export default AuctionSellComingSoon;