import styles from './ConditionTooltip.module.scss';
import { ClickAwayListener, IconButton, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useMediaQuery } from 'react-responsive';
import { MobileScreenWidth } from '../../../constants';
import { useState } from 'react';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#545454',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#545454',
    },
}));

interface ConditionTooltipProps {
    isLarge: boolean;
}

const ConditionTooltip = ({isLarge}: ConditionTooltipProps) => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });

    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    const handleTooltipToggle = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setOpen(prev => !prev);
    };

    const getTooltipContent = () => {
        return (
            <div className={styles.tooltipContainer}>
                <Typography className={styles.gradeHeader}>Grade</Typography><Typography className={styles.descriptionHeader}>Description</Typography>
                <Typography className={styles.gradeText}>A</Typography><Typography className={styles.descriptionText}>Unworn since purchase from the authorised dealer. Case +/- bracelet stickers may still be in tact</Typography>
                <Typography className={styles.gradeText}>B</Typography><Typography className={styles.descriptionText}>Like new but showing some signs of wear. Some case +/- bracelet stickers may be in tact.</Typography>
                <Typography className={styles.gradeText}>C</Typography><Typography className={styles.descriptionText}>Lightly worn. Superficial scratches and blemishes are evident.</Typography>
                <Typography className={styles.gradeText}>D</Typography><Typography className={styles.descriptionText}>Worn with markings, none substantial. Case edges still sharp, no evidence of over polishing. Bracelet may have some stretch.</Typography>
                <Typography className={styles.gradeText}>E</Typography><Typography className={styles.descriptionText}>Substantial markings to case/bracelet/dial/hands. Evidence of polishing. See condition pictures.</Typography>
                <Typography className={styles.gradeText}>F</Typography><Typography className={styles.descriptionText}>Substantial dings may be present on case/bracelet/dial/hands. Substantial evidence of polishing with soft case +/- bracelet edges. Bracelet may have significant stretch. See condition pictures.</Typography>
            </div>
        );
    }
    
    if (isDesktop) {
        return (
            <BootstrapTooltip title={getTooltipContent()}>
                <InfoOutlinedIcon className={isLarge ? styles.conditionIconLarge : styles.conditionIcon} />
            </BootstrapTooltip>        
        );
    } else {
        return (
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <div className={styles.clickAwayContainer}>
                    <BootstrapTooltip title={getTooltipContent()} PopperProps={{disablePortal: true}} onClose={handleTooltipClose} open={open} disableFocusListener disableHoverListener disableTouchListener>
                        <IconButton onClick={handleTooltipToggle} disableFocusRipple disableRipple disableTouchRipple>
                            <InfoOutlinedIcon className={isLarge ? styles.conditionIconLarge : styles.conditionIcon} />
                        </IconButton>
                    </BootstrapTooltip>
                </div>
            </ClickAwayListener>
        );
    }
}

export default ConditionTooltip;