import { createTheme } from "@mui/material";

export const muiTheme = createTheme({
    typography: {
        fontFamily: "'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif"
    },
    components: {
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid #C7C5C1',
                    minHeight: '48px',
                    maxHeight: '48px',
                    "&.Mui-expanded": {
                        minHeight: '48px',
                        maxHeight: '48px',
                    },
                }
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: '0'
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    color: "#E91B1B",
                    "&$error": {
                        color: "#E91B1B",
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    "&.MuiTab-root": {
                        color: '#717171',
                        fontSize: '14px',
                        fontWeight: '300',
                        textTransform: 'none',
                        padding: '0',
                    },
                    "&.Mui-selected": {
                        color: '#1d3053',
                        borderBottom: "2px solid #1d3053",
                    }
                }
            }
        }
    },
});