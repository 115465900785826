import { WatchInfoBuy } from "../models/WatchInfoBuy";
import { addHours } from 'date-fns/fp';
import { WatchInfoBuyDetails } from "../models/WatchInfoBuyDetails";

export const fakeData: WatchInfoBuyDetails = {
    id: '',
    brand: 'Rolex',
    model: 'Daytona',
    year: '2010',
    bids: 0,
    auctionEnd: addHours(8, new Date()),
    currentBid: 0,
    currentBidUser: 'jcaulfield',
    isFavourite: true,
    savedAsFavouriteCount: 23,
    sellerType: 'private',
    bidHistory: [{
        bidDate: '12 Dec 2022',
        bidDateDescription: '(12 Days Ago)',
        bidAmount: 12499,
        user: 'watchgovern'
    },
    {
        bidDate: '9 Dec 2022',
        bidDateDescription: '(15 Days Ago)',
        bidAmount: 11000,
        user: 'simlin9'
    },
    {
        bidDate: '09 Dec 2022',
        bidDateDescription: '(15 Days Ago)',
        bidAmount: 10800,
        user: '_linco-201'
    }],
    summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    specification: {
        modelNumber: '12345',
        condition: 'A',
        material: 'Steel',
        movement: 'Yes',
        lot: '1A',
        case: 'Yes',
        box: '160cm x 80cm',
        papers: 'Yes',
        waterproof: 'Yes'
    },
    conditionDetails: {
        description: 'Perfect clasp condition. Only slight scratch on the face of the watch.'
    },
    sellerInfo: {
        user: 'abcde',
        location: 'London, UK',
        sellerType: 'Private'
    },
    comments: [],
    images: ['watchDetails1.jpg','watchDetails2.jpg','watchDetails3.jpg','watchDetails4.jpg','watchDetails5.jpg','watchDetails6.jpg']
};

const comingSoonWatches: WatchInfoBuyDetails[] = [
    {
        id: 'watch001',
        brand: 'Rolex',
        model: '50th anniversary Submariner',
        year: '2008',
        bids: 0,
        currentBid: 0,
        currentBidUser: '',
        isFavourite: false,
        savedAsFavouriteCount: 0,
        sellerType: 'private',
        bidHistory: [],
        summary: 
`We are pleased to list this fine example of a 50th anniversary Rolex Submariner as our very first auction and invite bids from you! Colloquially known as the Kermit, due to the green colourway, the 50th anniversary Submariner was released in 2003 as a celebration to mark half a century of Submariner production. 
||
Huge premiums were charged in the early days for what was and remains an exciting watch. Notable departures from its 16610 black bezel Submariner brother included the green bezel and maxi dial markers. Being an example from the later production years, this Submariner has the engraved rehaut where you will find the unique watch serial number as well as "rolexrolexrolex" engraving. 
||
Get your bids in early for this collectors' favourite which boasts a timeless design and remains, arguably, the finest Rolex Submariner of the previous twenty years.
||
||
<b>Note from seller:</b>
||
The 50th anniversary Submariner made quite a stir in 2003 when it was released. So much so, I came across it in the fashion pages of The Sunday Times among Hermes belts and silk Versace shirts. The article spoke about the price being £2650, 'if you can get one'. 
||
Naively, I put my name down at a couple of places for the watch and never got a call. I did eventually buy the watch but not brand new. It came to me from a shop in Burlington Arcade and I've worn it pretty solidly for about five years. It keeps great time, is fantastically legible in the dark and helps time my eggs boiling in the morning, all while looking effortlessly suave. 
||
I hope the new owner enjoys it as much as I have. Wear it well!`,
        specification: {
            modelNumber: '16610LV',
            condition: 'E',
            material: 'Stainless steel',
            movement: 'Rolex 3135',
            lot: '1',
            case: '40mm',
            box: 'Present with hang tags x2',
            papers: 'Present',
            waterproof: '300 metres'
        },
        conditionDetails: {
            description: ''
        },
        sellerInfo: {
            user: 'Managed sale',
            location: 'London',
            sellerType: 'Private seller'
        },
        comments: [],
        images: [
            'RolexAnniversary/Anniversary2.jpg',
            'RolexAnniversary/Anniversary3.jpg',
            'RolexAnniversary/Anniversary4.jpg',
            'RolexAnniversary/Anniversary5.jpg',
            'RolexAnniversary/Anniversary6.jpg',
            'RolexAnniversary/Anniversary7.jpg',
            'RolexAnniversary/Anniversary8.jpg',
            'RolexAnniversary/Anniversary9.jpg',
        ]
    },
    {
        id: 'watch002',
        brand: 'Rolex',
        model: 'Daytona',
        year: '2017',
        bids: 0,
        currentBid: 0,
        currentBidUser: '',
        isFavourite: false,
        savedAsFavouriteCount: 0,
        sellerType: 'private',
        bidHistory: [],
        summary: 
`The 116520 Daytona needs no introduction to anyone with even a fleeting interest in watches. The first Daytona to house an in-house Rolex movement, the calibre 4130 containing 116520 was in production for sixteen years between 2000 and 2016.  Over its production life, there were variations on various components ranging from the dial writing to the bracelet design to the luminous material.
||
The word rare, often overused, really is warranted for this watch for the following strong reasons:
<ul><li>The delivery stickers all remain in tact</li><li>The guarantee card is blank with the watch originally purchased in the final few months of its production run</li><li>The hour markers and hands are fitted with blue "chromalight" which replaced the previously used green "luminova" in the final couple of years of production</li><li>Originally purchased in the UK</li>
||
The pictures are there for everyone to pour over and enjoy, however, only one lucky bidder will get to add this fine and rare watch to their collection! Bid early and bid well!
||
||
<b>Note from seller:</b>
||
This watch has a special memory for me. Back in 2012 I helped a friend source a white gold Rolex sky dweller from a provincial Rolex agent in Colchester. I got to know the owner of the shop, Adrian, very well and became good friends with a shared appreciation of watches. Unfortunately, after a difficult time, the business had to close.
||
Before the shutters came down on Upchurch The Jewellers, Adrian called to say he had something special in for me and that I should come in. I drove the 75 miles in suspense and was elated to see he was selling me his last ever Daytona, a watch I registered my interest in way back in 2012 when I helped with the sky dweller!
||
It's a beautiful piece and a watch I didn't want to sell but the time is right and wish the new owner all the very best with it. It's a wonderful piece.`,
        specification: {
            modelNumber: '116520',
            condition: 'A',
            material: 'Stainless steel',
            movement: 'Rolex 4130',
            lot: '2',
            case: '40mm',
            box: 'Present with hang tags x2',
            papers: 'Present',
            waterproof: '100 metres'
        },
        conditionDetails: {
            description: ''
        },
        sellerInfo: {
            user: 'Managed sale',
            location: 'London',
            sellerType: 'Private seller'
        },
        comments: [],
        images: [
            'Daytona/Daytona1.jpg',
            'Daytona/Daytona2.jpg',
            'Daytona/Daytona3.jpg',
            'Daytona/Daytona4.jpg',
            'Daytona/Daytona5.jpg',
            'Daytona/Daytona6.jpg',
            'Daytona/Daytona7.jpg',
            'Daytona/Daytona8.jpg',
        ]
    },
    {
        id: 'watch003',
        brand: 'Rolex',
        model: '"Polar" Explorer',
        year: 'Unknown',
        bids: 0,
        currentBid: 0,
        currentBidUser: '',
        isFavourite: false,
        savedAsFavouriteCount: 0,
        sellerType: 'private',
        bidHistory: [],
        summary: 
`Watch Shuffle invites you to bid on this fine Rolex Explorer. The 216570 or, "Polar" Explorer by it's colloquial name, finally appears to be getting the recognition and appreciation that it has always deserved. The orange 24 hour hand is a nod to the Rolex Explorer made famous by Steve McQueen and the square, but not too square 42mm case offers a size and shape that has a real charm to it. 
||
Here at Watch Shuffle, we feel the juxtaposition of brushed, highly polished and linear polishing are the most endearing features of this fine watch. We are excited to have this watch for sale without a reserve and invite you to bid early!
||
||
<b>Note from seller:</b>
||
This watch was a 31st birthday gift to myself from Ernest Jones, Westfield and has been used on all my travels since then. It's been to Asia and Europe a few times and served me faultlessly. 
||
It's time for a change now, though. Enjoy the journeys you make with the watch!`,
        specification: {
            modelNumber: '216570',
            condition: 'D',
            material: 'Stainless steel',
            movement: 'Rolex 3187',
            lot: '4',
            case: '42mm',
            box: 'Present with hang tags x2',
            papers: 'Absent',
            waterproof: '100 metres'
        },
        conditionDetails: {
            description: ''
        },
        sellerInfo: {
            user: 'Managed sale',
            location: 'London',
            sellerType: 'Private seller'
        },
        comments: [],
        images: [
            'Explorer/Explorer2.jpg',
            'Explorer/Explorer3.jpg',
            'Explorer/Explorer4.jpg',
            'Explorer/Explorer5.jpg',
            'Explorer/Explorer6.jpg',
            'Explorer/Explorer7.jpg',
        ]
    },
    {
        id: 'watch004',
        brand: 'Omega',
        model: 'Speedy Tuesday',
        year: '2017',
        bids: 0,
        currentBid: 0,
        currentBidUser: '',
        isFavourite: false,
        savedAsFavouriteCount: 0,
        sellerType: 'private',
        bidHistory: [],
        summary: 
`Watch Shuffle are very excited to present for auction this Omega Speedy Tuesday. Limited to just 2012 pieces worldwide and made to commemorate the fifth anniversary of #speedytuesday, this watch was made available to those clients who registered their interest on the Omega website in early 2017. Needless to say, the allocation was grossly oversubscribed for what is an interesting and unique Omega Speedmaster. Deliveries were made through Omega boutiques worldwide. 
||
The reverse panda dial sits in front of the Omega 1861 calibre and behind a domed hesalite crystal. The watch came and comes with an Omega leather strap and Omega Nato, both of which tuck into an Omega leather case alongside an Omega strap changing tool. Rarely seen at auction, this Speedy Tuesday is a watch which started off an Omega trend of online promotion and sale with delivery through boutiques. When this watch goes live, we'll ensure it ends on a Tuesday! #SpeedyTuesday
||
||
<b>Note from seller:</b>
||
I really like the look and pedigree of the speedy and this one presents very neatly. The registration window was only a matter of hours and I didn't think I'd be successful in acquiring it but was very lucky!
||
It's a really special watch and was always destined to be part of my collection and, as such, has never been used. I wish the new owner lots of enjoyment with this fabulous watch!`,
        specification: {
            modelNumber: '311.32.42.30.01.001',
            condition: 'A',
            material: 'Stainless steel',
            movement: 'Omega 1861',
            lot: '3',
            case: '42mm',
            box: 'Present with hang tag',
            papers: 'Present',
            waterproof: '50 metres'
        },
        conditionDetails: {
            description: ''
        },
        sellerInfo: {
            user: 'Managed sale',
            location: 'London',
            sellerType: 'Private seller'
        },
        comments: [],
        images: [
            'Speedy/Speedy2.jpg',
            'Speedy/Speedy3.jpg',
            'Speedy/Speedy4.jpg',
            'Speedy/Speedy5.jpg',
            'Speedy/Speedy6.jpg',
            'Speedy/Speedy7.jpg',
            'Speedy/Speedy8.jpg',
        ]
    },
    {
        id: 'watch005',
        brand: 'Rolex',
        model: 'Day Date 40',
        year: '2019',
        bids: 0,
        currentBid: 0,
        currentBidUser: '',
        isFavourite: false,
        savedAsFavouriteCount: 0,
        sellerType: 'private',
        bidHistory: [],
        summary: 
`The Day Date has known many iterations since its introduction in 1956. The model here is the current 228235 in Everose and comes on the President bracelet that was created for the launch of the Day Date back in 1956. Everose is the name given by Rolex to their 18ct pink gold alloy which they've patented and have been producing in their own foundry since 2005. 
||
The Day Date was the first certified chronometer wrist watch to display the day and date on the dial. The dial on the model we have here is "sundust" with 10 factory baguette diamonds. The sunray effect is created by applying grooves to the dial from the centre outwards, followed by physical vapour deposition of the dial colour and finished with a protective varnish. We think the diamonds have photographed well, however, you'd be forgiven for marvelling at their quality under a loupe! They are absolutely perfect!
||
||
<b>Note from seller:</b>
||
I bought this watch from a closing down sale at a Rolex agent in Lichfield. I'd been going to Salloways for about a decade for various bits and pieces and even bought the Rolex on my wrist from there. They were having a retirement sale and the price on this watch was too good to miss! It's not really something my wife, myself or our kids would wear and so it's time to realise the value and treat ourselves to something that will get some use!`,
        specification: {
            modelNumber: '228235',
            condition: 'A',
            material: '18ct Everose gold',
            movement: 'Rolex 3255',
            lot: '5',
            case: '40mm',
            box: 'Present with hang tags x2',
            papers: 'Present',
            waterproof: '100 metres'
        },
        conditionDetails: {
            description: ''
        },
        sellerInfo: {
            user: 'Managed sale',
            location: 'London',
            sellerType: 'Private seller'
        },
        comments: [],
        images: [
            'DayDate/Daydate1.jpg',
            'DayDate/Daydate2.jpg',
            'DayDate/Daydate3.jpg',
            'DayDate/Daydate4.jpg',
            'DayDate/Daydate5.jpg',
            'DayDate/Daydate6.jpg',
            'DayDate/Daydate7.jpg',
            'DayDate/Daydate8.jpg',
        ]
    },
    {
        id: 'watch006',
        brand: 'Tudor',
        model: 'Harrods Edition Black Bay',
        year: '2017',
        bids: 0,
        currentBid: 0,
        currentBidUser: '',
        isFavourite: false,
        savedAsFavouriteCount: 0,
        sellerType: 'private',
        bidHistory: [],
        summary: 
`Into its 7th year of production, the Harrods Black Bay was introduced in 2017 in what was rumoured to be a limited production run of 100 pieces. Demand was literally through the roof and Harrods could have sold thousands of pieces. The model remains in production today and it is said that several thousand watches have indeed been produced over the years. 
||
This model measures 41mm and comes with a steel bracelet as well as a nylon nato strap. The scratch proof sapphire crystal is domed; a nod to the Tudor dive watches of yesteryear. The dial and case are finished with the Harrods green with the famous Harrods "H" and the edition number engraved on the caseback.  The edition number is low - from within the first 50 watches produced.
||
||
<b>Note from seller:</b>
||
This watch was offered to me when I bought my wife her engagement ring from one of the boutiques in the fine jewellery room at Harrods. It doubled up as an engagement present from my wife, however, it was an impromptu purchase as she had already bought me a fabulous watch which I wear daily. The time has come for someone else to enjoy this watch.`,
        specification: {
            modelNumber: 'M79230G-0001',
            condition: 'A',
            material: 'Stainless steel',
            movement: 'Tudor MT5602',
            lot: '6',
            case: '41mm',
            box: 'Present with hang tags x2',
            papers: 'Present',
            waterproof: '200 metres'
        },
        conditionDetails: {
            description: ''
        },
        sellerInfo: {
            user: 'Managed sale',
            location: 'London',
            sellerType: 'Private seller'
        },
        comments: [],
        images: [
            'Tudor/Tudor1.jpg',
            'Tudor/Tudor2.jpg',
            'Tudor/Tudor3.jpg',
            'Tudor/Tudor4.jpg',
            'Tudor/Tudor5.jpg',
            'Tudor/Tudor6.jpg',
        ]
    },
]

export const getWatchBuyDetails = async (id: string) => {
    const found = comingSoonWatches.find(watch => watch.id === id);
    return Promise.resolve(found);
}

export const getWatchesByStatus = async (status: string): Promise<WatchInfoBuy[]> => {
    if (status !== 'comingsoon') {
        return [];
    }

    // For now generate some fake data
    const comingSoon: WatchInfoBuy[] = [
        {
            id: 'watch001',
            brand: 'Rolex',
            model: '50th anniversary Submariner',
            year: '2008',
            condition: 'E',
            bids: 0,
            currentBid: 0,
            isFavourite: false,
            sellerType: 'private',
            showroomImage: 'RolexAnniversary/Showroom.jpg'
        },
        {
            id: 'watch002',
            brand: 'Rolex',
            model: 'Daytona',
            year: '2017',
            condition: 'A',
            bids: 0,
            currentBid: 0,
            isFavourite: false,
            sellerType: 'private',
            showroomImage: 'Daytona/Showroom.jpg'
        },
        {
            id: 'watch003',
            brand: 'Rolex',
            model: '"Polar" Explorer',
            year: 'Unknown',
            condition: 'D',
            bids: 0,
            currentBid: 0,
            isFavourite: false,
            sellerType: 'private',
            showroomImage: 'Explorer/Showroom.jpg'
        },
        {
            id: 'watch004',
            brand: 'Omega',
            model: 'Speedy Tuesday',
            year: '2017',
            condition: 'A',
            bids: 0,
            currentBid: 0,
            isFavourite: false,
            sellerType: 'private',
            showroomImage: 'Speedy/Showroom.jpg'
        },        
        {
            id: 'watch005',
            brand: 'Rolex',
            model: 'Day Date 40',
            year: '2019',
            condition: 'A',
            bids: 0,
            currentBid: 0,
            isFavourite: false,
            sellerType: 'private',
            showroomImage: 'DayDate/Showroom.jpg'
        },        
        {
            id: 'watch006',
            brand: 'Tudor',
            model: 'Harrods Edition Black Bay',
            year: '2017',
            condition: 'A',
            bids: 0,
            currentBid: 0,
            isFavourite: false,
            sellerType: 'private',
            showroomImage: 'Tudor/Showroom.jpg'
        },        
    ];

    return Promise.resolve(comingSoon);
}