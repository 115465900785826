import styles from './FAQItem.module.scss';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';

export interface QuestionAndAnswer {
    id: string;
    question: string;
    answer: string[];
}

export interface FAQItemProps {
    index: number;
    item: QuestionAndAnswer;
}

const FAQItem = ({index, item}: FAQItemProps) => {
    const [showAnswer, setShowAnswer] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={styles.root}>
            <div className={styles.contentContainer}>

                <div className={styles.questionAndAnswerContainer}>
                    <div className={styles.questionContainer} onClick={() => setShowAnswer(prev => !prev)}>
                        <Typography className={styles.questionText}>{`${index + 1}. ${item.question}`}</Typography>
                    </div>
                    
                    {showAnswer ? item.answer.map((answer) => (
                        <Typography className={styles.answerText}>{answer}</Typography>
                    )) : null}
                </div>

                <div className={styles.iconContainer}>
                    <IconButton
                        className={styles.iconButton}
                        aria-label="Show/Hide Answer"
                        onClick={() => setShowAnswer(prev => !prev)}
                    >
                        {showAnswer ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                    </IconButton>
                </div>
    
            </div>
        </div>
    );
}

export default FAQItem;