import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import styles from './StatusFilter.module.scss';

export interface StatusFilterProps {
    selected: string;
    onChange: (value: string) => void;
    statusToCount: Record<string, number>;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: 1,
        '&:not(:first-of-type)': {
            margin: 1,
        },
        '&:first-of-type': {
            margin: 1,
        },
    },
}));

export const StatusFilter = ({selected, onChange, statusToCount}: StatusFilterProps) => {
    const toggleButtonClasses = {
        root: styles.statusButton,
        selected: styles.statusButtonSelected
    };

    return (
        <div className={styles.root}>
            <StyledToggleButtonGroup value={selected} onChange={(e, v) => onChange(v)} exclusive={true}>
                <ToggleButton classes={toggleButtonClasses} key='comingsoon' value='comingsoon' disableRipple>
                    <Typography className={styles.statusLabel} noWrap>Coming Soon</Typography>
                    <div className={styles.countContainer}>
                        <Typography className={styles.countLabel}>{statusToCount.comingsoon}</Typography>
                    </div>
                </ToggleButton>
                <ToggleButton classes={toggleButtonClasses} key='live' value='live' disableRipple>
                    <Typography className={styles.statusLabel}>Live</Typography>
                    <div className={styles.countContainer}>
                        <Typography className={styles.countLabel}>{statusToCount.live}</Typography>
                    </div>
                </ToggleButton>
                <ToggleButton classes={toggleButtonClasses} key='sold' value='sold' disableRipple>
                    <Typography className={styles.statusLabel}>Sold</Typography>
                    <div className={styles.countContainer}>
                        <Typography className={styles.countLabel}>{statusToCount.sold}</Typography>
                    </div>
                </ToggleButton>
            </StyledToggleButtonGroup>
        </div>
    );
}